
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted, onActivated } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";



import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

export default defineComponent({
    name: "referencials-park",
    emit: ["refreshData"],
    props: {
        park: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,

    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let isUpdate = false as boolean;
        let infrastructures = ref([]);
        let headings = ref([]);
        let data={};
        var name="";




        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editParkModalRef = ref<null | HTMLElement>(null);
        const parkDefault = {
            id: null,
            name: "",
            phone: "",
            infrastructure_type:1,
            infrastructure_id:"",
            address:"",
            // employee_id:{"id":9,"last_name":"Brown","text":"Brown","name":"BROWN","structure_type":1,"selected":true},
            employee_id:{},
            branche_employee_id:0,
            description: "",
            branches:[],
            branches_send:[],
            branche_heading:0,
            branche_name:"",
            branche_phone:"",
            units:{},

        };
        const parkData = ref<any>({});
        Object.assign(parkData.value, parkDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            // code: Yup.string().required().label(translate("employeeCodeLabel")),
            // last_name: Yup.string().required().label(translate("employeeLastNameLabel")),
            // first_name: Yup.string().required().label(translate("employeeFirstNameLabel")),
            // gender: Yup.string().required().label(translate("employeeGenderLabel")),
            // civil_status: Yup.string().required().label(translate("employeeCivilStatusLabel")),
            // id_number: Yup.string().required().label(translate("employeeIdNumberLabel")),

        });

        const submit = (values, actions) => {
            if (!submitButtonRef.value) {
                return;
            }





            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");


            if (isUpdate && props.park.id){
                parkData.value.id = props.park.id;

                // when we are in edit mode we will  affect units (from park_units table)
                // to branches_send so adding exsting ubits to add ones
                Object.keys(parkData.value.units).forEach(function(key) {
                        var units_obj = new Array(
                            Object.keys(parkData.value.units[key].heading_id).toString(),
                            parkData.value.units[key].name,
                            parkData.value.units[key].phone,
                            Object.keys(parkData.value.units[key].employee_id).toString());

                            parkData.value.branches_send.push(units_obj);

                });



            }


           // before sending to the backend we will delete some element unnecessary used only for frontend

        //    delete parkData.value.branches;
        parkData.value.branches = [];
           delete parkData.value.branche_heading;
           delete parkData.value.branche_name;
           delete parkData.value.branche_phone;

           // sending only the id of the employee
           if (parkData.value.employee_id) {
               parkData.value.employee_id = parkData.value.employee_id.id;
           }



            axios.post(ApiRoutes.parks.updateOrCreate,parkData.value)
                .then((response: AxiosResponse) => {
                    parkData.value.branches = new Array();
                    parkData.value.units = [];

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    if (response.data && response.data.success) {
                        parkData.value.branches=[];
                        // parkData.value.branches_send=[];



                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editParkModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {


                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    if (submitButtonRef.value) {
                        submitButtonRef.value.disabled = false;

                        submitButtonRef.value?.removeAttribute("data-kt-indicator");
                    }

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const getInfrastructures = async () => {
            const level = (parkData.value) ? parkData.value.infrastructure_type : 1;
            const response = await axios.get(ApiRoutes.infrastructures.list, { params: { level: level } });

            infrastructures.value = response.data;
        };

        // get branches heading types
        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.parks.types);

            headings.value = response.data;
        };

        // get branches heading types
        const changeValue = async (name) => {
            name.value = name;
        };


        const addBranche = () => {

            if (parkData.value.branche_heading != "" &&
                parkData.value.branche_name != "" &&
             parkData.value.branche_phone != "" &&
             parkData.value.branche_employee_id != "")
             {
                let branche_heading_id;


                Object.keys(headings.value).forEach((key) => {
                        if (parkData.value.branche_heading == headings.value[key].name ) branche_heading_id = headings.value[key].id;
                    });



                var arr_display = new Array(parkData.value.branche_heading,parkData.value.branche_name,parkData.value.branche_phone,parkData.value.branche_employee_id.text);
                var arr_send = new Array(branche_heading_id,parkData.value.branche_name,parkData.value.branche_phone,parkData.value.branche_employee_id.id);

                // use this variable array to display it in the front
                parkData.value.branches.push(arr_display);

                // use this variable array to send it to the backend
                parkData.value.branches_send.push(arr_send);


                // initialize data for new branch
                parkData.value.branche_heading = 0;
                parkData.value.branche_name = "";
                parkData.value.branche_phone = "";
                parkData.value.branche_employee_id={};

                const element: HTMLElement = document.getElementById('select2-brancheEmployeeId-container') as HTMLElement
                    element.innerHTML = translate('searchHeadBranche') + '...';


            }
        };

        const removeBranche = (index) => {
            parkData.value.branches.splice(index, 1);
        };

        const removeBrancheOnEdit = (index) => {
            parkData.value.units.splice(index, 1);
        };

        onMounted(() => {

            parkData.value.branches = [];
            getInfrastructures();
            getTypes();
            const modal = document.getElementById('kt_modal_edit_park');

            if (modal) modal.addEventListener('hidden.bs.modal', function(event) {
                Object.assign(parkData.value, parkDefault);
            });

            if (modal) modal.addEventListener('shown.bs.modal', function() {
                isUpdate = false;


                if (props.park) {
                    isUpdate = true;

                    parkData.value.employee_id = props.park.employee_id;
                    const element: HTMLElement = document.getElementById('select2-parkEmployeeId-container') as HTMLElement
                    element.innerHTML = props.park.employee_id.text;

                    parkData.value.branches_send = [];
                    // parkData.value.branches = [];

                    Object.keys(props.park).forEach((key) => {
                        if (parkDefault.hasOwnProperty(key)) parkData.value[key] = props.park[key];
                    });

                }
            });

            jQuery("#parkEmployeeId").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('searchHeadPark') + '...',
                // data:,
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employeesPosition,
                    data: function (params) {
                        return {
                            q: params.term,
                            name:"Chef d'atelier",
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                parkData.value.employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                    parkData.value.employee_id.push(data);


                jQuery("#parkEmployeeId").val('').trigger('change');
            });
            // en load jquery for park



            // load jquery for branche!!!
            jQuery("#brancheEmployeeId").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('searchHeadBranche') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employeesPosition,
                    data: function (params) {
                        return {
                            q: params.term,
                            // code:parkData.value.structure_id,

                            name:parkData.value.branche_heading,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                parkData.value.branche_employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };


                    parkData.value.branche_employee_id.push(data);


                jQuery("#brancheEmployeeId").val('').trigger('change');
            });
            // end load jquery for branche

        });


        return {
            translate,
            infrastructures,
            getInfrastructures,
            name,
            parkData,
            validationSchema,
            submit,
            headings,
            submitButtonRef,
            editParkModalRef,
            addBranche,
            removeBranche,
            removeBrancheOnEdit,
        };
    },
});
