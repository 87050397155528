
import axios from "axios";
import { useI18n } from "vue-i18n/index";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

export default defineComponent({
    name: "search-transport-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();
        let infrastructures = ref([]);
        let headings = ref([]);
        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchEmployeeModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            id: null,
            name: "",
            phone: "",
            infrastructure_type:1,
            infrastructure_id:"",
            address:"",
            employee_id: "",
            branche_employee_id:0,
            description: "",
            branches:[],
            branches_send:[],
            branche_heading:0,
            branche_name:"",
            branche_phone:"",
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

           if (queryParams.value.employee_id) {
               queryParams.value.employee_id = queryParams.value.employee_id.id;

           }else{
               queryParams.value.employee_id = 0;
           }

            if (!submitButtonRef.value) {
                return;
            }


            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchEmployeeModalRef.value);
        };


        const getInfrastructures = async () => {
            const level = (queryParams.value) ? queryParams.value.infrastructure_type : 1;
            const response = await axios.get(ApiRoutes.infrastructures.list, { params: { level: level } });

            infrastructures.value = response.data;
        };

        // get branches heading types
        const getTypes = async () => {
            const response = await axios.get(ApiRoutes.parks.types);

            headings.value = response.data;
        };












        onMounted(() => {
            getInfrastructures();
            getTypes();;

            const modal = document.getElementById('kt_modal_search_park');



            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });

             // load jquery for park !!!
            jQuery("#parkEmployeeIdSearch").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('searchHeadPark') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.employees,
                    data: function (params) {
                        return {
                            q: params.term,
                            code:'D01',
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let emp = e.params.data;
                queryParams.value.employee_id = emp;
                let data = {
                    id              : emp.id,
                    heading         : null,
                    name            : emp.last_name,
                };

                    queryParams.value.employee_id.push(data);


                jQuery("#parkEmployeeIdSearch").val('').trigger('change');
            });
            // en load jquery for park



        });

        return {
            translate,

            queryParams,
            submit,
            submitButtonRef,
            searchEmployeeModalRef,

            infrastructures,

            getInfrastructures,

        };
    },
});
